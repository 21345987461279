@use "sass:math";
@import './typographies.module';

@mixin no-scroll-bar() {
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    height: 0;
    width: 0;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: ' ';
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }

  > div,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin galaxy-background() {
  background: url('https://res.cloudinary.com/dgsgylfvr/image/upload/c_scale,w_800/v1620640615/moon-chicken-dine-in/background.webp'),
    $dark;
  background-size: 100% auto;
  background-position: center;
}

@mixin perspective-grid-background() {
  background: url('https://res.cloudinary.com/dgsgylfvr/image/upload/c_scale,e_gradient_fade:25,o_50,w_800/v1622195588/moon-chicken-dine-in/perspective-grid.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

@mixin skeleton-loader() {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: transparent;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
