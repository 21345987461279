@import 'src/assets/styles/colors.module';
@import 'src/assets/styles/typographies.module';

.BottomTabBar {
  background-color: $dark;
  height: 58px;
  padding: 0 12px;
  padding-bottom: env(safe-area-inset-bottom, 0px);

  display: flex;
  justify-content: space-between;

  .BottomTabBar__container {
    position: relative;
    width: 64px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $offWhite;
    @include link-small;
    .BottomTabBar__counter {
      opacity: 0;
      position: absolute;
      top: 4px;
      left: calc(100% - 28px);
      min-width: 16px;
      height: 16px;
      padding: 2px 4px;
      box-sizing: border-box;
      background-color: $secondary;
      border-radius: 8px;
      color: $black;
      @include link-xsmall;
      text-align: center;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      &--visible {
        opacity: 1;
      }
    }
    svg {
      font-size: 20px;
      margin-bottom: 2px;
    }
    &--active {
      color: $secondary;
      svg {
        -webkit-animation: pulsate-bck 0.5s ease-in-out both;
        animation: pulsate-bck 0.5s ease-in-out both;
      }
    }
  }
  /* ----------------------------------------------
  * Generated by Animista on 2021-6-15 13:43:10
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation pulsate-bck
  * ----------------------------------------
  */
  @-webkit-keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
