$dark: #121212;
$black: #14142b;
$blackLight: #4e4b66;
$grey: #6e7191;
$greyLight: #a0a3bd;
$greyLighter: #d9dbe9;
$greyLightest: #eff0f6;
$brokenWhite: #f7f7fc;
$offWhite: #fcfcfc;

$primary: #6419b1;
$secondary: #f0c543;
$tertiary: #1f2065;
$error: #dd093b;
$success: #00ba88;
$warning: #f4b740;
$support1: #494bd7;
$support2: #7dfac9;
$gradientPrimary: linear-gradient(114.44deg, #9737fa 0%, #e82a7a 100%);
$gradientSecondary: linear-gradient(114.44deg, #1f2065 0%, #494bd7 100%);

$overlay10: rgba(0, 0, 0, 0.1);
$overlay40: rgba(0, 0, 0, 0.4);
$overlay60: rgba(0, 0, 0, 0.6);
$overlay80: rgba(0, 0, 0, 0.8);
$overlay90: rgba(0, 0, 0, 0.9);

$whiteOverlay10: rgba(255, 255, 255, 0.1);
$whiteOverlay40: rgba(255, 255, 255, 0.4);
$whiteOverlay60: rgba(255, 255, 255, 0.6);
$whiteOverlay80: rgba(255, 255, 255, 0.8);
$whiteOverlay90: rgba(255, 255, 255, 0.9);

:export {
  dark: #121212;
  black: #14142b;
  blackLight: #4e4b66;
  grey: #6e7191;
  greyLight: #a0a3bd;
  greyLighter: #d9dbe9;
  greyLightest: #eff0f6;
  brokenWhite: #f7f7fc;
  offWhite: #fcfcfc;

  primary: #6419b1;
  secondary: #f0c543;
  tertiary: #1f2065;
  error: #e82a7a;
  success: #00ba88;
  warning: #f4b740;
  support1: #494bd7;
  support2: #7dfac9;
  gradientPrimary: linear-gradient(114.44deg, #9737fa 0%, #e82a7a 100%);
  gradientSecondary: linear-gradient(114.44deg, #1f2065 0%, #494bd7 100%);

  overlay10: rgba(0, 0, 0, 0.1);
  overlay40: rgba(0, 0, 0, 0.4);
  overlay60: rgba(0, 0, 0, 0.6);
  overlay80: rgba(0, 0, 0, 0.8);
  overlay90: rgba(0, 0, 0, 0.9);
  
  whiteOverlay10: rgba(255, 255, 255, 0.1);
  whiteOverlay40: rgba(255, 255, 255, 0.4);
  whiteOverlay60: rgba(255, 255, 255, 0.6);
  whiteOverlay80: rgba(255, 255, 255, 0.8);
  whiteOverlay90: rgba(255, 255, 255, 0.9);
}
