@import "./assets/styles/constants.module";
@import "./assets/styles/colors.module";
@import "./assets/styles/reset.module";
@import "./assets/styles/typographies.module";
@import "./assets/styles/mixins.module";

:root {
  --status-bar-height: 24px;
  --bottom-bar-height: 0;
}

.App {
  box-sizing: border-box;
  min-width: $pageMinWidth;
  max-width: $pageMaxWidth;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include galaxy-background();
}

.touchable-opacity {
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  @extend .disable-text-select;
}

.touchable-opacity:active {
  opacity: 0.7;
}

.disable-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
