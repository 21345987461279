@mixin display-large {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 48px;
  line-height: 50px;
}
@mixin display-large-bold {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 48px;
  line-height: 50px;
}
@mixin display-medium {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 32px;
  line-height: 36px;
}
@mixin display-medium-bold {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 32px;
  line-height: 36px;
}
@mixin display-small {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 24px;
  line-height: 32px;
}
@mixin display-small-bold {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 24px;
  line-height: 32px;
}
@mixin text-large {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 600;
  font-size: 20px;
  line-height: 28px;
}
@mixin link-large {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 700;
  font-size: 20px;
  line-height: 28px;
}
@mixin text-large-black {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 20px;
  line-height: 28px;
}
@mixin text-medium {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 400;
  font-size: 16px;
  line-height: 24px;
}
@mixin link-medium {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 700;
  font-size: 16px;
  line-height: 24px;
}
@mixin text-medium-black {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 16px;
  line-height: 24px;
}
@mixin text-regular {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 400;
  font-size: 14px;
  line-height: 20px;
}
@mixin link-regular {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 700;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-regular-black {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 900;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-small {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 400;
  font-size: 12px;
  line-height: 18px;
}
@mixin link-small {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 700;
  font-size: 12px;
  line-height: 18px;
}
@mixin text-xsmall {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 400;
  font-size: 10px;
  line-height: 14px;
}
@mixin link-xsmall {
  font-family: Exo, sans-serif;
  font-variation-settings: 'wght' 700;
  font-size: 10px;
  line-height: 14px;
}